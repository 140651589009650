
:root{
  --primary-card-background:#fff;
  --body-gradient-2:#eef1f5;
  --body-gradient-1:#ffffff;
  --body-bg:#ffffff;
  --dark-bg:#161515;
  --secondary-bg: rgb(246, 246, 246);
  --mobile-header-bg:rgb(255 255 255 / 53%);
}


$primary: #008833;
$yellow: #f8f000;
$red: #f30000;
$primary-01: rgba($primary, 0.88);
$primary-02: rgba($primary, 0.20);
$light-background:rgba($primary, 0.04);
$medium-background:rgba($primary, 0.30);
$neutral:#f1f3f6;
$secondary: #fbfbfb;
$light-grey: #adadad;
$dark-background: #383838;
$shadow1:rgba($primary, 0.10) 0px 7px 29px 0px;
$shadow2:0 4px 30px rgba(0, 34, 64, 0.066);
$gradient1: linear-gradient(to top, var(--body-gradient-1) 0%, var(--body-gradient-2) 100%);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: var(--body-bg);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
div{
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}
* {
box-sizing: border-box;
}
a{
  color: $primary;

  &:hover{
    color: #adadad;
  }
}
.flex-grow{
  flex-grow: 1;
}
.radius-1{
  border-radius: 5px;
}
.radius-2{
  border-radius: 10px;
}
.radius-3{
  border-radius: 15px;
}
.radius-4{
  border-radius: 20px;
}
.radius-circle{
  border-radius: 100%;
}
.opacity-0{
opacity: 0;
}
.opacity-01{
opacity: .1;
}
.opacity-02{
opacity: .2;
}
.opacity-03{
opacity: .3;
}
.opacity-04{
opacity: .4;
}
.opacity-05{
opacity: .5;
}
.opacity-07{
opacity: .7;
}
.opacity-08{
opacity: .8;
}
.opacity-09{
opacity: .9;
}
.fs-30{
font-size: 30px;
}
.fs-25{
font-size: 25px;
}
.fs-20{
font-size: 20px;
}
.fs-18{
font-size: 18px;
}
.fs-15{
font-size: 15px;
}
.fs-12{
font-size: 12px;
}
.fs-8{
font-size: 8px;
}
.relative{
position: relative;
}
.absolute{
position: absolute;
}
.fixed{
position: fixed;
}
.top-0{
top: 0;
}
.right-0{
right: 0;
}
.bottom-0{
bottom: 0;
}
.left-10{
left: 10px;
}
.top-10{
top: 10px;
}
.right-10{
right: 10px;
}
.bottom-10{
bottom: 10px;
}
.left-20{
left: 20px;
}
.top-20{
top: 20px;
}
.right-20{
right: 20px;
}
.right-30{
right: 30px;
}
.bottom-20{
bottom: 20px;
}
.left-20{
left: 20px;
}
.left-40{
left: 40px;
}
.top-40{
top: 40px;
}
.right-40{
right: 40px;
}
.bottom-40{
bottom: 40px;
}
.left-40{
left: 40px;
}
.hide{
display: none !important;
}
.block{
display: block !important;
}
.cursor-pointer{
cursor: pointer;
}
.cursor-grab{
cursor: grab;
}
.cursor-move{
cursor: move;
}
.flex{
display: flex !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.column{
flex-direction: column;
}
.gap-5{
gap:5px;
}
.gap-10{
gap:10px;
}
.gap-15{
gap:15px;
}
.gap-20{
gap:20px;
}
.gap-35{
gap:35px;
}
.my-10{
margin-top: 10px;
margin-bottom: 10px;
}
.my-15{
margin-top: 15px;
margin-bottom: 15px;
}
.my-20{
margin-top: 20px;
margin-bottom: 20px;
}
.mt-10{
margin-top: 10px;
}
.mt-20{
margin-top: 20px;
}
.mt-30{
margin-top: 30px;
}
.mt-10vh{
margin-top: 10vh;
}
.mb-0{
  margin-bottom: 0 !important;
}
.mb-10{
margin-bottom: 10px;
}
.mb-20{
margin-bottom: 20px;
}
.mb-30{
margin-bottom: 30px;
}
.m-0{
  margin: 0;
}
.p-0{
  padding: 0px;
}
.p-5{
padding: 5px;
}
.pt-5{
padding-top: 5px;
}
.pt-15{
padding-top: 15px;
}

.pt-10{
padding-top: 10px;
}
.px-10{
padding-left: 10px;
padding-right: 10px;
}
.px-15{
padding-left: 15px;
padding-right: 15px;
}
.px-25{
padding-left: 25px;
padding-right: 25px;
}
.py-10{
padding-top: 10px;
padding-bottom: 10px;
}
.py-15{
padding-top: 15px;
padding-bottom: 15px;
}
.py-25{
padding-top: 25px;
padding-bottom: 25px;
}
.py-35{
padding-top: 35px;
padding-bottom: 35px;
}
.p-5{
  padding: 5px;
}
.p-10{
  padding: 10px;
}
.p-15{
padding: 15px;
}
.p-25{
padding: 25px;
}
.p-15{
padding: 15px;
}
.w-100{
width: 100% !important;
}
.w-80{
width: 80% !important;
}
.w-70{
width: 70% !important;
}
.w-60{
width: 60% !important;
}
.w-50{
width: 50% !important;
}
.w-40{
width: 40% !important;
}
.w-30{
width: 30% !important;
}
.w-20{
width: 20% !important;
}
.w-10{
width: 10% !important;
}
.w-300{
  width: 300px;
}
.vw-100{
width: 100vw;
}
.mw-sm{
max-width:300px;
}
.mw-md{
max-width:400px;
}
.mw-lg{
max-width:500px;
}
.mw-xl{
max-width:600px;
}
.mw-xxl{
max-width:700px;
}
.align-center{
align-items: center;
}
.align-end{
align-items: flex-end;
}
.space-between{
justify-content: space-between;
}
.space-around{
justify-content: space-around;
}
.justify-center{
justify-content: center;
}
.justify-end{
justify-content: flex-end;
}
.h-100{
height: 100% !important;
}
.vh-100{
height: 100vh;
}
.text-sm{
font-size: 25px;
font-weight: 500;
}
.color-primary{
color: $primary;
}
.color-red{
color: $red;
}
.color-yellow{
color: $yellow;
}
.color-white{
color: #fff;
}
.color-black{
color: rgb(15, 15, 15);
}
.color-secondary{
color: $secondary;
}
.color-grey{
color: $light-grey;
}
.fw-4{
  font-weight:400;
}
.fw-5{
  font-weight:500;
}
.fw-6{
  font-weight:600;
}
.bold{
  font-weight: bold;
}
.text-md{
font-size: 50px;
font-weight:700;
}
.text-xl{
font-size: 70px;
font-weight:700;
}
.text-center{
text-align: center;
}
.text-right{
text-align: right;
}
.bg-grey{
  background-color: #f3f3f3;
}
.bg-white{
  background-color: #fff;
}
.bg-blur{
backdrop-filter: blur(1px) brightness(0.97);
}
.bg-blur-md{
backdrop-filter: blur(3px) brightness(0.97);
}
.bg-blur-xl{
backdrop-filter: blur(5px) brightness(0.97);
}
.bg-primary{
background-color: $primary !important;
}
.bg-neutral{
background-color: $neutral !important;
}
.bg-secondary{
background-color: $secondary !important;
}
.bg-gradient1{
  background-image: $gradient1;
}
.bg-dark-01{
background-color: rgba(0, 0, 0, 0.026);
}
.ar-16-9{
  aspect-ratio: 16/9;
}
.ar-1{
  aspect-ratio: 1/1;
}
.x-scroll{
  overflow-x: scroll;
}
.overflow-hidden{
  overflow: hidden;
}
.white-space-nowrap{
  white-space: nowrap;
}
.word-break{
  word-break: break-all;
}
.hover-scale:hover{
  transform: scale(1.1);
}
.transition-01{
  transition: .1s;
}
.transition-02{
  transition: .2s;
}
.chart-skeleton{
  padding: 30px;
  border-style: solid;
  border-color: var(--body-gradient-2);
  border-width: 0px 0px 10px 10px;
}
.table-skeleton{
  border: solid 5px;
  padding: 30px;
  border-radius: 10px;
  border-color:var(--body-gradient-2);
}
//css generico
input{
  font-size: 16px !important;
}
.ant-tour {
    max-width: calc(100% - 20px);
}
.ant-picker-filled, .ant-select-filled .ant-select-selector {
    background: rgb(0 0 0 / 2%) !important;
}
.di-logo{
  max-width: 150px;
}
.ant-picker-datetime-panel{
  flex-wrap: wrap !important;
}
.ant-divider{
  .ant-divider-inner-text{
      text-wrap: wrap;
      min-width: 180px;
  }
}
.ant-row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.ant-layout-sider {
  position: fixed !important;
  height: calc(100vh - 40px);
  z-index: 1;
}
.ant-list-item{
  padding: 0px !important;
}
.ant-skeleton{
  width: 100% !important;
}
.ant-select{
  min-width: 150px;
}
.ant-drawer-content-wrapper {
  max-width: 100%;
}
.changelog-modal{
  margin: 40px 10px;
}
.di-secondary-btn {
    border: unset;
    border: 1px solid transparent;
}
.ant-table-row:hover, .ant-table-cell-row-hover {
    background: #05772f24 !important;
}
tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > .ant-table-cell {
    padding: 20px;
    border: solid 1px $primary-02;
}

//css dashboard
.di-header{
  max-height: 60px;
  padding: 10px;
  width: 100%;
  backdrop-filter:blur(2px);
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--mobile-header-bg);

  & > div{
    height: 100%;
  }
  .di-show-drawer {
    color:$primary;
    font-size: 25px;
  }
}
.di-logo-extended{
  width: 120px;
}
.di-dashboard{
  background-image: $gradient1;

  .ant-layout{
    background: unset;
  }
  .ant-layout-content{
    margin-left: 200px;
    padding: 20px !important;
    gap: 10px;
  }
}
.di-card{
  background: var(--primary-card-background);
  padding: 20px;
  border-radius: 20px;
  box-shadow: $shadow2;
}
.di-table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}

.di-chart-wrapper{
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: 2px;  /* Firefox */

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}
.di-sidebar{
  box-shadow: $shadow2;
}
.di-mobile-menu{

  .ant-drawer-body, .ant-drawer-header{
    padding: 10px;
  }

}
.di-navigation{
  border-inline-end:unset !important;

  &.primary{
    //height: calc(100% - 110px);
  }
  .ant-menu-item-selected {
    color: #fff;
    background-color: #008833;
  }
  .di-menu-logout{
    color: #008833;
    background: #f7f8fa;
  }
}
.ant-menu-light{
  background: transparent;
}
.pie-chart{
  height: 500px;
}

.show-more-wrapper{

  position: relative;

  .show-more-content{
    max-height: 400px;
    overflow: hidden;
    transition: .2s;
    padding-bottom: 50px;
    -webkit-mask-image: linear-gradient(#fff 70%, transparent);
    mask-image: linear-gradient(#fff 70%, transparent);
    &.show{
      max-height: 2000px;
      mask-image: none;
      -webkit-mask-image: none;
    }
  }

  .show-more-button{
    position: absolute;
    bottom:0;
    z-index: 10;
  }

}

//css tavoli aperti
.opened-table-modal{
  .ant-modal-body{
    .ant-row{
      padding: 5px 10px;
    }
    .ant-row:nth-child(odd){
      background-color: var(--primary-card-background);
    }
    .ant-row:nth-last-child(1){
      background-color: var(--primary-card-background);
    }
  }
}
//css dashboard

//css login
.di-login-form-area {
  border-radius: 40px 0px 0px 40px;
  height: 100vh;
  box-shadow: -20px 0px 1px 1px #f9f9f9, -20px 0px 30px rgb(0 34 64 / 7%);
  padding: 50px;
}
.di-login-image-area img{
  width: 500px;
  padding: 20px;
}

//animazioni
.animate{
  animation-timing-function: ease-in;

}
.fadeIn{
  animation: fadeIn .2s forwards;
}
.fadeInUp{
  animation: fadeInUp .2s forwards;
}
.scaleIn{
  animation: scaleIn .2s forwards;
}
.pulse{
    animation: pulse 3s infinite;
}

@keyframes pulse {
  0%{opacity: 100%}
  50%{opacity: 20%}
  100%{opacity: 100%}
}

@keyframes fadeIn {
  0%{opacity: 0}
  100%{opacity: 1}
}
@keyframes fadeInUp {
  0%{opacity: 0; transform: translateY(20px);}
  100%{opacity: 1;  transform: translateY(0px);}
}
@keyframes scaleIn {
  0%{transform: scale(0);}
  100%{transform: scale(1);}
}
//media query
@media all and (max-width:600px) {
  .ant-picker-date-panel, .ant-picker-header, .ant-picker-body, .ant-picker-content, .ant-picker-time-panel{
    width: 100% !important;
  }
  .ant-picker-dropdown {
      width: calc(100% - 20px);
      left: 10px !important;
  }
  .ant-picker-panel-layout{
    flex-wrap: wrap !important;
  }
  .mobile-w-100{
    width: 100%;
  }
  .ant-picker-presets{
    height: 50px;
    max-width: 100vw !important;

    & li{
      display: inline;
    }
  }
  .pie-chart{
    height: 300px;
  }
}
@media all and (min-width: 990px){
  .di-sidebar{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 20px;
  }
  .mobile-hide{
    display: block;
  }
  .mobile-show{
    display: none !important;
  }
  .desktop-hide{
    display: none;
  }
}
@media all and (max-width: 990px){
  .mobile-hide{
    display: none !important;
  }
  .ant-layout-content{
    margin-left: 0px !important;
  }
  .mobile-show{
    display: block;
  }
  .mobile-x-scroll{
    overflow-x: scroll;
  }
  .di-dashboard  .ant-layout-content{
    margin-top: 70px !important;
    padding: 10px !important;
  }
  .di-navigation.primary {
    //height: calc(100% - 55px);
  }
  .di-login-wrapper{
    justify-content: start;

    .ant-row{
      display: block;
      height: 100%;
    }
  }
  .di-login-image-area, .di-login-form-area {
    height: auto;
  }
  .di-login-image-area{
    height: 25%;
  }
  .di-login-image-area img{
    width: 300px;
  }
  .di-login-form-area {
    border-radius: 40px 40px 0px 0px !important;
    height: 75%;
    box-shadow: 0px -20px 1px 1px #f9f9f9, 0px -20px 30px rgb(0 34 64 / 7%);

    .di-login-form-wrapper{
      justify-content: flex-start;
    }
    
  }
}
